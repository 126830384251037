import QrcodeSearch from './page/QrcodeSearch';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<QrcodeSearch />} />
        <Route path="/:parameter" element={<QrcodeSearch />} />
      </Routes>
    </Router>
  );
}


export default App;
