import React, { useContext, useState, useEffect } from 'react';
import { Typography, Input, Button, Layout, Table} from 'antd';
import axios from 'axios'
import '../style/landing.css'
import QRCode from 'qrcode';
import { useParams } from "react-router-dom";

import {
  SearchOutlined,
  CalendarOutlined
} from '@ant-design/icons';

const { Header, Content, Footer } = Layout;

const API_URL = process.env.REACT_APP_API_URL
const API_TOKEN = process.env.REACT_APP_API_TOKEN
const IMAGE_URL = process.env.REACT_APP_PUBLIC_URL


function QrcodeSearch() {

  const { parameter } = useParams();

  const [searchText, setsearchText] = useState('');
  const [listRegister, setListRegister] = useState([]);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  const [statusThaiFlag, setStatusThaiFlag] = useState('activeFlag flagLanguag');
  const [statusEngFlag, setStatusEngFlag] = useState('disabledFlag flagLanguag');

  const [headerText, setHeaderText] = useState('ค้นหาบัตรเข้าร่วมกิจกรรม TxID x BS21');
  const [placeholderText, setPlaceholderText] = useState('ค้นหาด้วยชื่อ - นามสกุล หรือ หมายเลขบัตรประชาชน หรือ passport id');

  const [alertRequireMinimumText, setAlertRequireMinimumText] = useState('กรุณากรอกข้อมูลอย่างน้อย 3 ตัวอักษร หรือ 3 ตัวเลข');
  const [alertNotFoundText, setAlertNotFoundText] = useState('ไม่พบข้อมูลของท่าน กรุณากรอกข้อมูลให้ครบถ้วน');

  const [showTicket, setShowTicket] = useState(false)
  const [ticketDetail, setTicketDetail] = useState(false)


  const checkRegisterList = async () => { 
      
      setShowTicket(false)
      setTicketDetail(setTicketDetail)

      if(searchText.length < 3){
        alert(alertRequireMinimumText)
        return ''
      }

      try {
        const response = await axios.post(`${API_URL}searchPerson`, {
          eventId: 1,
          searchText: searchText,
        }, {
            headers: {
              'Authorization': `bearer ${API_TOKEN}`,              
              'Content-Type': 'application/json'
            }
        })

        if(response.status == 200){
          setListRegister(response.data); 
          if(response.data.length == 0){ alert(alertNotFoundText) }
        }else{
          setListRegister([])
          alert(alertNotFoundText)
        }

    } catch (error) {
        console.log(error);
    }

  }

  const checkRegisterByBib = async (bibNumber) => {
    try{
      const response = await axios.post(`${API_URL}searchPersonByBib`, {
        eventId: 1,
        bibNumber: bibNumber,
      }, {
          headers: {
            'Authorization': `bearer ${API_TOKEN}`,              
            'Content-Type': 'application/json'
          }
      })

      if(response.status == 200){
        if(!response.data){ alert(alertNotFoundText) }
        else{ await showTicketFn(response.data); }
      }

    } catch (error) {
        console.log(error);
    }

  }

  const setCurrentLanguageFn = async (lang) =>{

    if(lang == 'thai'){
      setStatusThaiFlag('activeFlag flagLanguage')
      setStatusEngFlag('disabledFlag flagLanguage')

      setHeaderText('ค้นหาบัตรเข้าร่วมกิจกรรม TxID x BS21')
      setPlaceholderText('ค้นหาด้วยชื่อ นามสกุล หรือ หมายเลขบัตรประชาชน / passport id')

      setAlertRequireMinimumText('กรุณากรอกข้อมูลอย่างน้อย 3 ตัวอักษร หรือ 3 ตัวเลข')
      setAlertNotFoundText('ไม่พบข้อมูลของท่าน กรุณากรอกข้อมูลให้ครบถ้วน')
    
    }else if(lang == 'eng'){
      setStatusThaiFlag('disabledFlag flagLanguage') 
      setStatusEngFlag('activeFlag flagLanguage')

      setHeaderText('Search Ticket For Join TxID x BS21')
      setPlaceholderText('Find by FirstName With LastName, Citizen ID / Passport No')

      setAlertRequireMinimumText('Please Type Minimum 3 character Or 3 Number')
      setAlertNotFoundText('Your Information Not Found. Please fill in the information completely.')

    }
  }

  const setsearchTextFn = async (e) => {
    setsearchText(e.target.value)
  }

  const checkInputForm = async (e) => {
    if (e.key === 'Enter') {
      checkRegisterList();
    }
  }

  const handleDownload = async () => {
    const link = document.createElement('a');
    link.href = qrCodeUrl; // Base64 QR code URL
    link.download = 'TxID_Qrcode.png'; // Filename for the downloaded image
    link.click(); // Trigger the download

  };

  const showTicketFn = async (record) => {

    const qrCodeDataUrl = await QRCode.toDataURL(record.qrcodeText, { width: 250, margin: 5 });
    const logo = IMAGE_URL+'image/txid_logo.png'
    const logoBs21 = IMAGE_URL+'image/bs21_logo.png'

    if (logo) {
      // Load QR code and logo images into canvases for merging
      const qrCodeImg = new Image();
      const logoImg = new Image();
      const logoBs21Img = new Image();

      qrCodeImg.src = qrCodeDataUrl;
      logoImg.src = logo;
      logoBs21Img.src = logoBs21;

      qrCodeImg.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas size to match QR code size
        canvas.width = qrCodeImg.width;
        canvas.height = qrCodeImg.height;

        ctx.drawImage(qrCodeImg, 0, 0);
        ctx.drawImage(logoImg, 60, 10, 70, 25);
        ctx.drawImage(logoBs21Img, 140, 10, 70, 25);

        ctx.font = "16px Prompt";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";

        const qrcodeTextArr = record.qrcodeText.split("_");
        ctx.fillText(qrcodeTextArr[1], canvas.width / 2, canvas.height - 10); // Position text


        setQrCodeUrl(canvas.toDataURL());
      };
    } else {
      setQrCodeUrl(qrCodeDataUrl); // Use QR code without logo if no logo is provided
    }

    setShowTicket(true)
    setTicketDetail(record)
  }

  const resetSearch = async (record) => {
    setsearchText()
    setShowTicket(false)
    setListRegister([])
  }

  const columns = [
    {
        title: 'Status / Reg.Code',
        width: 200,
        dataIndex: 'registration_code',
        key: 'registration_code',
        fixed: 'left',
        render: (text, record) => (
          <div>
            <div className='registerStatus'>complete</div>
            {text}
          </div>
        )    
    },
    {
      title: 'Participant Details',
      width: 600,
      dataIndex: 'id_card_number',
      key: 'id_card_number',
      render: (text, record) => (
        <div>
          <div className='clientName'>{record.name+" "+record.surename}</div>
          <div className='eventName'>Bangsaen21 2024</div>
          <div><CalendarOutlined /> 15 December 2024</div>
          
        </div>
      )    
    },
    {
        title: 'Bib',
        width: 50,
        dataIndex: 'bib',
        key: 'bib',
        responsive: ['md', 'lg', 'xl'],
        render: (text, record) => (
          <div>
            {text}
          </div>
        ),
    },
    {
        title: 'Ticket',
        width: 50,
        dataIndex: 'email',
        key: 'email',
        render: (text, record) => (
          <div>
            <Button type="primary" onClick={()=>showTicketFn(record)}>View</Button>
          </div>
        ),
    }
  ]

  useEffect(()=>{
    if(parameter){
      checkRegisterByBib(parameter)  
    }
  },[])


  return (
    <Layout>
       <Header className='headerSection' style={{ display: 'flex', alignItems: 'center' }}>
        <div className='headerContent'>
          <div className='logoSection' onClick={()=>resetSearch()}><img src={IMAGE_URL+"image/txid_logo.png"} className='txidLogo' />&nbsp;&nbsp;<img src={IMAGE_URL+'image/bs21_logo.png'} className='bs21Logo'/></div>
          <div><img src={IMAGE_URL+'image/thai_flag.png'} id='flagThai' className={statusThaiFlag} onClick={()=>setCurrentLanguageFn('thai')}/>&nbsp;&nbsp;&nbsp;<img src={IMAGE_URL+'image/eng_flag.png'} id='flagEng' className={statusEngFlag} onClick={()=>setCurrentLanguageFn('eng')}/></div>
        </div>
      </Header>
      <Content className='mainContent' >
          <div className='searchSection'>
            <h1 className='header_text'>{headerText}</h1>
            <Input name='searchText' 
                className='inputSearchText' 
                value={searchText} placeholder={placeholderText} 
                onChange={setsearchTextFn}
                suffix={<SearchOutlined onClick={checkRegisterList}/>}
                onKeyDown={checkInputForm}
            />
          </div>
          {!showTicket &&listRegister && listRegister.length > 0 && (
            <Table 
              columns={columns}
              dataSource={listRegister}
              pagination={false}
              className='tableRegister'
              rowKey="registration_code"
            />
          )}

          {showTicket && 
            <div className='ticketSection'>
              <div className='ticketHeader'>Participant Details</div>
              <div className='ticketContent'>
                <div className='ticketDetailSection'>
                  <div className='titleSubHeader'> Status / Reg.Code </div>
                  <div className='registerStatus'> complete </div>
                  <div className='registerMainDetail'> {ticketDetail.registration_code} </div>
                  <div className='titleSubHeader'> Client </div>
                  <div className='registerMainDetail'> {ticketDetail.name} {ticketDetail.surename}</div>
                  <div className='titleSubHeader'> Event name </div>
                  <div className='registerMainDetail'> Bangsaen21 2024</div>
                  <div className='titleSubHeader'> Date</div>
                  <div className='registerMainDetail'> <CalendarOutlined /> 15 December 2024 </div>
                  <div className='titleSubHeader'> BIB No</div>
                  <div className='registerMainDetail'> {ticketDetail.bib}</div>
                </div>
                <div className='qrcodeSection'>
                  <div className='ticketHeader'>Ticket</div>
                  <div className='ticketDescription'>ท่านสามารถดาวน์โหลด QR Code และนำไปเข้าร่วมกิจกรรมได้เลย</div>
                  <img src={qrCodeUrl} alt="QR Code" style={{ width: '250px', height: '250px', margin: '10px' }} />
                  <div className='tooltip'>(สามารถกดค้างที่รูปเพื่อบันทึกรูปลงคลังภาพ)</div><br />
                  <Button onClick={handleDownload}>Download QR Code</Button>
                </div>
              </div>
            </div>
          }
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <img src='image/txid_logo_footer.png' />
        <div className='copyRight'>Copyright ©2024 Produced by LogicSpace Co., Ltd.</div>
      </Footer>
    </Layout>
  );
}

export default QrcodeSearch;
